











































































































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import MapView from '@/components/core/Map.vue';
import { User } from "@/store/user";
import { Auth } from "@/store/auth";
import { Core } from "@/store/core";
import { Map } from "@/store/map";
import { Product } from "@/store/product";
import moment from "moment"; 
import _ from 'lodash'
import {
    City
} from "@/store/city";
@Component({
    components: { MapView },
    computed: {
    },
})

export default class PostSaller extends Vue {
    choices: any = {}
    page: number = 1
    status: any = ['มีสินค้า', 'สินค้าหมด', 'ขายแล้ว', 'ยกเลิก']
    search: any = '' 
    headers: any = [
        { text: '', value: 'id' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Iron (%)', value: 'iron' },
    ] 

    async created() {
        await Core.switchLoad(true)
        await this.loadFarm();
        await this.loadProduct();
        await this.loadChoice()
        await Core.switchLoad(false)
        this.response = true
    }  

    farm: any = {}
    response: boolean = false;
    user: any = null
    profile: any = null

    async loadFarm() {
        this.user = await Auth.getUser()
        this.profile = await User.getProfileFull();
        this.farm = await Core.getHttp(`/api/user/farm/${this.user.pk}/`) 
    }
 
    public async loadChoice() {
  
        this.choices = {
            "product_type": await Product.ProductType,
            "sell_type": await Product.SaleType,
            "price_type": await Product.PriceType,
            'status': await Product.StatusBuy
        }
    }

    getData(choices:any,val:any){ 
        let data:any = _.find(choices,{id:Number(val)})
        return  (data)?data.name:'-' 
    }

    products: any = null
    async loadProduct() {
        this.products = await Core.getHttp(`/api/default/product/?farm=${this.farm.id}&product_type=1&search=${this.search}`) 
    }

    public async updateProduct(product: any) {
        let store = await Core.putHttp(`/api/default/products/${product.id}/`, product)
        if (store.id) {
            alert("Save product success")
            await this.loadProduct()
        }
    } 

    dateOut(date:any){
        let convert = moment(date).format('DD/MM/YYYY')
        return (convert != "Invalid date"	)?convert:"ไม่มีกำหนด"
    }
 
}
